import { blue } from "@ant-design/colors";
import { Row, Col, Space, Card } from "antd";
import moment from "moment";
import React, { memo } from "react";
import { useIntl } from "react-intl";

import { TMessage } from "@/api";

import { DeleteMessage } from "./actions";
import { IChatProps } from "./types";

interface IMessageProps extends Omit<IChatProps, "conversation"> {
  item: TMessage;
}

export const Message = memo(({ item, state, player }: IMessageProps) => {
  const intl = useIntl();
  const isFromPlayer = item.from.userId === player.id;

  return (
    <Row
      justify={isFromPlayer ? "start" : "end"}
      style={{ marginBottom: "8px" }}
    >
      <Col>
        <Space
          size={[2, 2]}
          direction="vertical"
          style={{ position: "relative" }}
        >
          <Card
            style={{
              background: isFromPlayer ? blue[2] : "#d3d3d3",
              borderRadius: "12px",
              maxWidth: "400px",
              wordBreak: "break-word",
            }}
            aria-label={intl.formatMessage({ defaultMessage: "Message" })}
          >
            <span>{item.text}</span>
          </Card>
          <span
            style={{
              whiteSpace: "nowrap",
              opacity: 0.8,
              display: "flex",
              alignItems: "center",
              justifyContent: isFromPlayer ? "flex-start" : "flex-end",
              fontSize: 10,
              marginRight: "8px",
            }}
          >
            {isFromPlayer ? player.login : state.admin.name}
            {" | "}
            {moment(item.createdAt).format("DD.MM.YYYY HH:mm")}
            <DeleteMessage state={state} item={item} />
          </span>
        </Space>
      </Col>
    </Row>
  );
});

Message.displayName = "Message";
